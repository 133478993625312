var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"article-list"}},[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center pb-5"},[_c('v-btn',{staticClass:"me-3",attrs:{"color":"primary","to":{ name: 'content-articles-create' }}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_c('span',[_vm._v("Create Article")])],1)],1)]),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"items":_vm.articlesListTable,"options":_vm.options,"server-items-length":_vm.totalArticleListTable,"loading":_vm.loading,"items-per-page":100,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.image,"width":"60"}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.name,"large":""},on:{"update:returnValue":function($event){return _vm.$set(item, "name", $event)},"update:return-value":function($event){return _vm.$set(item, "name", $event)},"save":function($event){return _vm.updateOneProperty(item.id, 'name', item.name)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 title w-500px"},[_vm._v(" Update Name ")]),_c('v-text-field',{attrs:{"rules":[_vm.max60chars],"label":"Edit","full-width":"","single-line":"","counter":"","autofocus":""},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]},proxy:true}],null,true)},[_c('span',{staticClass:"whitespace-no-wrap inline-editable"},[_vm._v(_vm._s(_vm.short(item.name)))])])]}},{key:"item.slug",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.slug,"large":""},on:{"update:returnValue":function($event){return _vm.$set(item, "slug", $event)},"update:return-value":function($event){return _vm.$set(item, "slug", $event)},"save":function($event){return _vm.updateOneProperty(item.id, 'slug', item.slug)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 title w-500px"},[_vm._v(" Update Slug ")]),_c('v-text-field',{attrs:{"rules":[_vm.max60chars],"label":"Edit","full-width":"","single-line":"","counter":"","autofocus":""},model:{value:(item.slug),callback:function ($$v) {_vm.$set(item, "slug", $$v)},expression:"item.slug"}})]},proxy:true}],null,true)},[_c('span',{staticClass:"whitespace-no-wrap inline-editable"},[_vm._v(_vm._s(_vm.short(item.slug)))])])]}},{key:"item.is_published",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-switch',{attrs:{"color":"success","hide-details":""},on:{"change":function($event){return _vm.toggleArticlePublishState(item.id, item.is_published)}},model:{value:(item.is_published),callback:function ($$v) {_vm.$set(item, "is_published", $$v)},expression:"item.is_published"}})],1)]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.defaultDate(item.updated_at)))])]),_c('span',{staticClass:"text-xs text-no-wrap"},[_vm._v(_vm._s(_vm.defaultTime(item.updated_at)))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":{ name: 'content-articles-edit', params: { id: item.id } }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.deleteConfirmState.id = item.id; _vm.deleteConfirmState.visible = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)]}}],null,true),model:{value:(_vm.selectedTableData),callback:function ($$v) {_vm.selectedTableData=$$v},expression:"selectedTableData"}}),_c('delete-confirm-dialog',{attrs:{"visible":_vm.deleteConfirmState.visible,"id":_vm.deleteConfirmState.id},on:{"close":function($event){_vm.deleteConfirmState.visible = false; _vm.deleteConfirmState.id = null},"confirm":_vm.deleteArticle}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }